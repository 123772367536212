export const Wallet = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
    <path d="M1.94531 28.1726V20.3635C1.94531 17.0498 4.6316 14.3635 7.94531 14.3635H40.4908C43.8045 14.3635 46.4908 17.0489 46.4908 20.3626C46.4908 22.0215 46.4908 23.9259 46.4908 25.9453M1.94531 36.1908V43.9999C1.94531 47.3136 4.63161 49.9999 7.94532 49.9999H40.4908C43.8045 49.9999 46.4908 47.3139 46.4908 44.0002C46.4908 42.3612 46.4908 40.466 46.4908 38.4181" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <path d="M8.6272 14.3636L20.5766 2.41421C21.3577 1.63316 22.624 1.63317 23.405 2.41421L25.5545 4.56364L15.7545 14.3636L27.7039 2.41421C28.4849 1.63316 29.7513 1.63316 30.5323 2.41421L42.4817 14.3636" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <mask id="path-3-inside-1_55_8" fill="white">
      <path d="M23.3271 32.1817C23.3271 28.2454 26.5181 25.0544 30.4544 25.0544H49.9453C50.4976 25.0544 50.9453 25.5022 50.9453 26.0544V38.309C50.9453 38.8613 50.4976 39.309 49.9453 39.309H30.4544C26.5181 39.309 23.3271 36.118 23.3271 32.1817Z" />
    </mask>
    <path d="M23.3271 32.1817C23.3271 28.2454 26.5181 25.0544 30.4544 25.0544H49.9453C50.4976 25.0544 50.9453 25.5022 50.9453 26.0544V38.309C50.9453 38.8613 50.4976 39.309 49.9453 39.309H30.4544C26.5181 39.309 23.3271 36.118 23.3271 32.1817Z" stroke="#F5C14F" strokeWidth="4" mask="url(#path-3-inside-1_55_8)" />
    <circle cx="1.94535" cy="32.1819" r="0.890909" fill="#F5C14F" />
    <circle cx="30.4545" cy="32.1818" r="1.67273" stroke="#F5C14F" strokeWidth="2" />
  </svg>
)