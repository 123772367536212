export const Forklift = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="44" viewBox="0 0 60 44" fill="none">
    <path d="M8.86618 4.62134V21.2417C8.86618 21.3804 8.97076 21.4941 9.10829 21.51C10.2245 21.635 11.0816 22.6205 11.0009 23.7879C10.9281 24.8427 10.0733 25.6975 9.01736 25.7714C7.76702 25.8566 6.72583 24.8677 6.72583 23.6367M20.3048 13.8385L25.9947 23.6947" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.5884 24.9929L9.04547 4.99881C8.30891 3.72346 8.74539 2.09348 10.0207 1.35805C10.4413 1.11594 10.8994 1 11.3518 1C12.2725 1 13.1682 1.47854 13.6626 2.33332L18.3158 10.3937" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.8224 27.6027C27.8497 27.4185 27.8634 27.2298 27.8634 27.0377C27.8634 24.8701 26.1061 23.1128 23.9384 23.1128C21.7719 23.1128 20.0146 24.8701 20.0146 27.0377C20.0146 27.2298 20.0283 27.4185 20.0556 27.6027M52.0165 37.3735H55.9732C57.2293 37.3735 58.2466 36.3562 58.2466 35.1002V28.6211C58.2466 27.3651 57.2293 26.3478 55.9732 26.3478H51.5289C50.7707 26.3478 50.0614 25.9693 49.6397 25.3396L45.2817 18.831C45.0739 18.5207 44.7928 18.2663 44.4633 18.0905C44.1338 17.9146 43.766 17.8227 43.3926 17.8228H35.5132C34.2571 17.8228 33.2398 18.8401 33.2398 20.0961V37.3735H40.7634" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M46.3897 43.0001C49.4971 43.0001 52.0162 40.481 52.0162 37.3736C52.0162 34.2662 49.4971 31.7471 46.3897 31.7471C43.2823 31.7471 40.7632 34.2662 40.7632 37.3736C40.7632 40.481 43.2823 43.0001 46.3897 43.0001Z" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M46.3896 39.59C47.6137 39.59 48.6061 38.5976 48.6061 37.3735C48.6061 36.1493 47.6137 35.157 46.3896 35.157C45.1655 35.157 44.1731 36.1493 44.1731 37.3735C44.1731 38.5976 45.1655 39.59 46.3896 39.59Z" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.0721 33.3405H2.54958C2.10968 33.3405 1.75391 33.6962 1.75391 34.1361V36.5777C1.75391 37.0176 2.10968 37.3734 2.54958 37.3734H8.3682M19.6212 37.3734H33.2397V33.3405M33.2397 33.3405H17.9174M33.2397 33.3405V27.6025H14.4846C14.0447 27.6025 13.689 27.9583 13.689 28.3982V31.7548" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.9944 43.0001C17.1019 43.0001 19.621 40.481 19.621 37.3736C19.621 34.2662 17.1019 31.7471 13.9944 31.7471C10.887 31.7471 8.36792 34.2662 8.36792 37.3736C8.36792 40.481 10.887 43.0001 13.9944 43.0001Z" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.9943 39.59C15.2185 39.59 16.2108 38.5976 16.2108 37.3735C16.2108 36.1493 15.2185 35.157 13.9943 35.157C12.7702 35.157 11.7778 36.1493 11.7778 37.3735C11.7778 38.5976 12.7702 39.59 13.9943 39.59Z" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M40.092 26.3477H33.2397M51.5287 26.3477H44.0704M1.75391 42.9999H58.2465M36.4731 29.4719H37.7164M58.2465 33.5635H55.2699C54.3451 33.5635 53.5953 32.8137 53.5953 31.8889C53.5953 30.9641 54.3451 30.2144 55.2699 30.2144H58.2465V33.5635Z" stroke="#F5C14F" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)