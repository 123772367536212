import { useEffect, useState } from "react"

export const useNetworkImage = (url: string) => {

  const [iamgeUrl, setImageUrl] = useState(`${url}4x.webp`)

  const updateNetworkState = () => {
    if (!navigator || !('connection' in navigator)) return

    //@ts-ignore
    const effectiveType = navigator.connection?.effectiveType

    switch (effectiveType) {
      case ('slow-2g'):
        setImageUrl('')
        break
      case '2g':
        setImageUrl(`${url}0.5x.webp`)
        break
      case '3g':
        setImageUrl(`${url}1x.webp`)
        break
      case '4g':
        setImageUrl(`${url}2x.webp`)
        break
      default:
        setImageUrl(`${url}4x.webp`)
        break
    }
  }


  useEffect(() => {
    if (navigator && (navigator as any)?.connection)
      updateNetworkState()
  }, [])

  return iamgeUrl
}