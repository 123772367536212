export const Clock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
    <path d="M26 11.1035V13.8966" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <path d="M26 38.1035V40.8966" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <path d="M11.1035 26L13.8966 26" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <path d="M38.1035 26L40.8966 26" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <path d="M26 2C26.5523 2 27 1.55228 27 1C27 0.447715 26.5523 0 26 0V2ZM50 26C50 39.2548 39.2548 50 26 50V52C40.3594 52 52 40.3594 52 26H50ZM26 50C12.7452 50 2 39.2548 2 26H0C0 40.3594 11.6406 52 26 52V50ZM2 26C2 12.7452 12.7452 2 26 2V0C11.6406 0 0 11.6406 0 26H2ZM42.1937 8.50473C47.2215 12.6184 50 19.0176 50 26H52C52 18.4954 49.0092 11.497 43.4602 6.95681L42.1937 8.50473Z" fill="#F5C14F" />
    <path d="M42 12V7H47" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <path d="M18.0045 9.18331C27.2922 4.76757 38.4009 8.71703 42.8167 18.0047C47.2324 27.2923 43.283 38.4011 33.9953 42.8168C24.7077 47.2326 13.5989 43.2831 9.18314 33.9955C6.47865 28.3071 6.91208 21.9356 9.78799 16.837" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <circle cx="26" cy="26" r="2.72414" stroke="#F5C14F" strokeWidth="2" />
    <path d="M28.3276 23.6723L36.2413 15.7585M23.6724 23.6723L19.9482 19.9482" stroke="#F5C14F" strokeWidth="2" strokeLinecap="round" />
    <circle cx="12.9655" cy="12.0345" r="0.931035" fill="#F5C14F" />
    <circle cx="31" cy="1" r="1" fill="#F5C14F" />
  </svg>
)