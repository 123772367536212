import React from 'react'
import { Btn } from '../Btn/Btn'
import { useNetworkImage } from '../../hooks/useNetworkImage'

import './Map.css'

export const Map: React.FC = () => {

  const imgUrl = useNetworkImage(`${process.env.PUBLIC_URL + 'img/map'}`)

  const isMobile = window.screen.width <= 900

  return (
    <div className="map dfc">
      <div className="dfsb">
        <div className="section">
          <p className='text'>
            Перевозки по городу, области и всей
            {
              !isMobile ? <br /> : ' '
            }
            России
            <span className='yellow-text italic'> (местонахождение — г. Юрга)</span>
          </p>

          {
            !isMobile && (
              <Btn
                title='Заказать эвакуатор'
                onClick={() => window.location.href = 'tel:+79236156909'}
              />
            )
          }

        </div>

        <div className="section">
          <img src={imgUrl} alt='map' />
        </div>

        {
          isMobile && (
            <Btn
              title='Заказать эвакуатор'
              onClick={() => window.location.href = 'tel:+79236156909'}
            />
          )
        }
      </div>
    </div>
  )
}
