import React from 'react'

import './FlowCard.css'

export const FlowCard: React.FC<PropsType> = ({
  img,
  text
}) => (
  <div className="flow-card dfsb">
    <div className="flow-card-img">
      {img}
    </div>
    <div className="flow-card-text">
      {text}
    </div>
  </div>
)

type PropsType = {
  img: JSX.Element
  text: JSX.Element
}