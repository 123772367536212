import React from 'react'
import { Btn } from '../Btn/Btn'
import { Logo } from '../icons/Logo'

import './Header.css'

export const Header: React.FC = () => {
  const isMobile = window.screen.width <= 900

  return (
    <div className='header dfsb main-layout'>

      <div className='info-bar dfsb'>
        <div className='logo-wrapper'>
          <Logo
            width={isMobile ? "74" : "128"}
            height={isMobile ? "55" : "90"}
          />
        </div>

        <div className='info'>
          <p className='yellow-text'>Эвакуация и техпомощь 24/7</p>
          <p>Работаем <span className='italic'>круглосуточно</span>. Звоните!</p>
        </div>
      </div>

      <Btn
        title='+7 (923) 615 69 09'
        onClick={() => window.location.href = 'tel:+79236156909'}
      />
    </div>
  )
}