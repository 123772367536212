import { useNetworkImage } from '../../hooks/useNetworkImage'
import { Btn } from '../Btn/Btn'

import './Main.css'

export const Main: React.FC = () => {
  const imgUrl = useNetworkImage(process.env.PUBLIC_URL + 'img/main')

  return (
    <div className='main'>
      <div className='img-wrapper' style={{ backgroundImage: `url(${imgUrl})` }}>

        <div className='main-layout'>
          <div className='dfsb main-inner'>
            <div className='left-block'>
              <div className='title-wrapper'>
                <h1 className='title'>Услуги эвакуатора Юрга</h1>

                <h2>Перевозки по городу, области и всей России</h2>

                <Btn
                  className='order-btn'
                  title='Заказать'
                  onClick={() => window.location.href = 'tel:+79236156909'}
                />
              </div>
            </div>
            <div className='description-wrapper right-block'>
              <p className='description yellow-text bold upper'>
                Ваш надёжный партнёр для перевозки <br />
                авто, мото и спецтехники
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
