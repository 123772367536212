import { Clock } from "../icons/Clock"
import { Forklift } from "../icons/Forklift"
import { Peppers } from "../icons/Peppers"
import { Wallet } from "../icons/Wallet"

export const flowCards = [
  {
    img: <Peppers />,
    text:
      <p className="card-text">
        <span className="bold">Позвоните </span>
        нам по телефону или
        <span className="bold"> напишите </span>
        в мессенджер
      </p>
  },
  {
    img: <Clock />,
    text:
      <p className="card-text">
        <span className="bold">Обсудим </span> ситуацию,
        <span className="bold"> согласуем </span>
        сроки подачи эвакуатора и
        <span className="bold"> договоримся </span>
        о цене
      </p>
  },
  {
    img: <Forklift />,
    text:
      <p className="card-text">
        Эвакуатор
        <span className="bold"> приедет </span>
        по нужному адресу,
        <span className="bold">выполнит </span>
        погрузку и доставку до места
      </p>
  },
  {
    img: <Wallet />,
    text:
      <p className="card-text">
        Вы
        <span className="bold"> оплачиваете </span>
        стоимость оказанных услуг
        <span className="bold"> по факту </span>
        выполненной работы
      </p>
  }
]