import React from 'react'
import { Header } from './components/Header/Header'
import { Main } from './components/Main/Main'
import { Footer } from './components/Footer/Footer'
import { Map } from './components/Map/Map'
import { Flow } from './components/Flow/Flow'

import './App.css'

export const App: React.FC = () => (
  <div className='app'>

    <div className='app-inner'>
      <Header />
      <Main />
      <Map />
      <Flow />
      <Footer />
    </div>

  </div>
)