import React from 'react'
import { flowCards } from './cards'
import { FlowCard } from './FlowCard/FlowCard'

import './Flow.css'

export const Flow: React.FC = () => (
  <div className="flow">
    <div className="flow-title">
      <h2>Процесс заказа и оказания услуг</h2>
    </div>
    <div className="dfc">
      <div className="flow-cards dfsb">
        {
          flowCards.map((card, index) => (
            <FlowCard
              key={index}
              img={card.img}
              text={card.text}
            />
          ))
        }
      </div>
    </div>
  </div>
)
