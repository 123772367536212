import React from 'react'
import { Btn } from '../Btn/Btn'
import { useNetworkImage } from '../../hooks/useNetworkImage'
import { Phone } from '../icons/Phone'
import { Wup } from '../icons/Wup'

import './Footer.css'

export const Footer: React.FC = () => {
  const imgUrl = useNetworkImage(process.env.PUBLIC_URL + 'img/footer')

  return (
    <div className='footer'>
      <div className='footer-bg' style={{ backgroundImage: `url(${imgUrl})` }}>

        <div className='main-layout'>
          <div className='footer-title h1'>
            Связь с нами
          </div>
          <div className='footer-btns dfsb'>
            <Btn
              className='footer-btn dfc'
              title='+7 (923) 615 69 09'
              onClick={() => window.location.href = 'tel:+79236156909'}
              img={<Phone />}
            />
            <Btn
              className='footer-btn dfc green'
              title='Написать в What’s Up'
              onClick={() => window.location.href = 'whatsapp://send?phone=79236156909'}
              img={<Wup />}
            />
          </div>
        </div>

      </div>
    </div>
  )
}
