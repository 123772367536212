import React from 'react'

import './Btn.css'

export const Btn: React.FC<PropsType> = ({
  onClick,
  title,
  className = '',
  img
}) => (
  <div className={`btn yellow-bg black-text ${className}`} onClick={onClick}>
    {
      img && (
        img
      )
    }
    {title}
  </div>
)

type PropsType = {
  onClick: () => void
  title: string
  className?: string
  img?: JSX.Element
}